#heroAnimation {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  $bg-url: "../img/redBg.png";
  $bg-width: 50px;
  $bg-height: 50px;
  z-index: -1;
  /* Animations */
  @-webkit-keyframes bg-scrolling-reverse {
    100% {
      background-position: $bg-width $bg-height;
    }
  }
  @-moz-keyframes bg-scrolling-reverse {
    100% {
      background-position: $bg-width $bg-height;
    }
  }
  @-o-keyframes bg-scrolling-reverse {
    100% {
      background-position: $bg-width $bg-height;
    }
  }
  @keyframes bg-scrolling-reverse {
    100% {
      background-position: $bg-width $bg-height;
    }
  }

  @-webkit-keyframes bg-scrolling {
    0% {
      background-position: $bg-width $bg-height;
    }
  }
  @-moz-keyframes bg-scrolling {
    0% {
      background-position: $bg-width $bg-height;
    }
  }
  @-o-keyframes bg-scrolling {
    0% {
      background-position: $bg-width $bg-height;
    }
  }
  @keyframes bg-scrolling {
    0% {
      background-position: $bg-width $bg-height;
    }
  }

  /* Main styles */

  color: #999;
  font: 400 16px/1.5 exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  text-align: center;
  /* img size is 50x50 */
  background: url($bg-url) repeat 0 0;

  -webkit-animation: bg-scrolling-reverse 0.92s infinite; /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 0.92s infinite; /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 0.92s infinite; /* Opera 12+ */
  animation: bg-scrolling-reverse 0.92s infinite; /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
