* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$mainColor: #da0037;
$bgColor: #171717;
$bgColor-2: #444444;
$textColor: #ededed;
$mainColor-opacity: rgba($mainColor, 0.7);
$gray: #abb2bf;

@mixin gradinent($color, $size, $blur) {
  background: $color;
  border-radius: 50%;
  filter: blur($blur);
  width: $size;
  height: $size;
}

::-webkit-scrollbar {
  background-color: $bgColor;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: $mainColor;
}
body {
  background-color: $bgColor;
  overflow-x: hidden;
}
.hash {
  font-size: 24px;
  color: $mainColor;
  margin-right: 3px;
}
.navbar {
  background-color: $bgColor;
  font-size: 20px;
  button {
    color: $textColor;
  }
  .logo {
    height: 64px;
  }
  .nav-link {
    color: $textColor;
    &.active {
      color: $mainColor;
    }
  }
}
.hidden {
  display: none;
}
a.btn-custom {
  text-decoration: none;
  color: $textColor;
  border: 1px solid $mainColor;
  padding: 10px;
  margin: 10px 0;
  display: inline-block;
  transition: all 0.5s;
  &:hover {
    background-color: $mainColor;
  }
}

.home {
  position: relative;
  background-color: rgba($bgColor, 0.7);
  height: calc(100vh - 90px);

  .hero {
    height: 70svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
    padding: 20px;

    .hero-content {
      flex: 1;
      color: $textColor;
      p {
        color: $gray;
        display: block;
        font-size: 24px;
      }
      h1 {
        min-width: 300px;
        font-size: 48px;
        font-weight: 600;
        span {
          color: $mainColor;
        }
      }
    }
    @media (max-width: 768px) {
      margin: 25px auto;
    }
  }
  .hero-img {
    text-align: center;
    margin: auto;
    #avatar {
      width: 400px;
      border-bottom: 1px solid $mainColor;
      @media (max-width: 1200px) {
        width: 200px;
      }
    }
  }
}

.section-header {
  color: #fff;
  margin: 20px;
  width: fit-content;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: $mainColor;
    width: 100%;
    height: 2px;
    bottom: 5px;
    left: 110%;
  }
}

.projects-filter {
  color: white;
  ul {
    margin: auto;
    border-radius: 12px;
    background-color: rgba($bgColor-2, 0.5);
    border: 1px solid $bgColor-2;
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  .option {
    display: inline-block;
    list-style: none;
    border: 1px solid $mainColor;
    padding: 5px;
    margin: 5px;
    min-width: 80px;
    text-align: center;
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.5s;
    &:hover {
      background-color: $mainColor-opacity;
    }
    &.active {
      background-color: $mainColor;
    }
  }
}
.projects {
  margin: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  gap: 30px;
  .project-card {
    transition: all 0.5s;
    border: 1px solid $textColor;
    animation: filterAn 0.5s ease-in;
    animation-fill-mode: backwards;
    img {
      max-width: 100%;
    }
    &:hover {
      border-color: $mainColor;
      box-shadow: 0 0 25px $mainColor-opacity;
      .project-skills {
        border-color: $mainColor;
      }
    }
    .project-skills {
      transition: 0.5s;
      border-bottom: 1px solid $textColor;
      border-top: 1px solid $textColor;
      padding: 5px;
      span {
        display: inline-block;
        color: $gray;
        font-size: 20px;
        padding: 5px;
      }
    }
    .info {
      padding: 15px;
    }
    .title {
      color: $textColor;
    }
    .discription {
      color: $gray;
    }
    .links a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      border: 1px solid $mainColor;
      padding: 10px;
      color: $textColor;
      margin: 0 5px 5px;
      &::before {
        z-index: -1;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        background-color: $mainColor-opacity;
        width: 0;
        transition: all 0.5s;
      }
      &:hover::before {
        width: 100%;
      }
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    .project-card {
      .project-skills span {
        font-size: 16px;
      }
      .title {
        font-size: 22px;
      }
    }
  }
}
@keyframes filterAn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
@media (max-width: 768px) {
  h1,
  h2 {
    font-size: 24px !important;
  }
  p,
  .project-skills span {
    font-size: 16px !important;
  }
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $bgColor;
  align-items: center;
  .logo {
    width: 64px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      border-top: 1px solid $mainColor;
      padding: 2px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      animation: spinner2 1s ease-in infinite;
      border-radius: 50%;
    }
    img {
      animation: spinner 1s ease-in infinite;
      width: 100%;
    }
  }
}
@keyframes spinner {
  0%,
  100% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(360deg);
  }
}
@keyframes spinner2 {
  0%,
  100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
}

.about {
  border-radius: 12px;
  background-color: rgba($bgColor-2, 0.5);
  border: 1px solid $bgColor-2;
  position: relative;
  width: 100%;
  padding: 20px;
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  .info {
    min-width: 300px;
    flex: 1;
    color: $gray;
    span {
      font-size: 18px;
      color: $textColor;
      display: block;
      margin-bottom: 20px;
    }
  }
  .skills {
    min-width: 300px;
    flex: 1;
    text-align: center;
    .my-skills {
      color: $mainColor;
      text-transform: uppercase;
      font-weight: bold;
    }
    .skills-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
      .skill {
        color: $gray;
        background-color: $mainColor;
        padding: 10px;
        margin: 5px;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    @include gradinent($mainColor-opacity, 400px, 50px);
    top: 50%;
    left: -100px;
    z-index: -1;
    transform: translateY(-50%);
  }
}
footer {
  padding: 20px;
  margin-top: 50px;
  border-top: 1px solid $mainColor;
  color: $gray;
  text-align: center;
  .Contact {
    background-color: rgba($mainColor, 0.5);
    padding: 10px;
    border: 1px solid $mainColor;
    top: 20px;
    right: 100px;
    .links {
      a {
        color: $gray;
        display: inline-block;
        margin: 5px;
        transition: all 0.5s;
        i {
          font-size: 24px;
        }
        &:hover {
          color: $textColor;
        }
      }
    }
  }
  span {
    margin-top: 20px;
    display: block;
  }
}
